
//import Carousel from './Carousel';
import StarterProjectsColumn from "./StarterProjectsColumn";
import GuidesColumn from './GuidesColumn';

function Main() {
    return (
        <main>
            <h1 className="text-body-emphasis">Get started with Bootstrap</h1>
            <p className="fs-5 col-md-8">Quickly and easily get started with Bootstrap's compiled, production-ready files with this barebones example featuring some basic HTML and helpful links. Download all our examples to get started.</p>
        
            <div className="mb-5">
            <a href="https://github.com/Weevilcorp" className="btn btn-primary btn-lg px-4">View on Github</a>
            </div>
        
            <hr className="col-3 col-md-2 mb-5" />
        
            <div className="row g-5">
                <StarterProjectsColumn />            
                <GuidesColumn />
            </div>
        </main>    
    );
}

export default Main;