/* eslint-disable jsx-a11y/anchor-is-valid */

function GuidesColumn() {
    return (
        <div className="col-md-6">
            <h2 className="text-body-emphasis">Guides</h2>
            <p>Read more detailed instructions and documentation on using or contributing to Bootstrap.</p>
            <ul className="list-unstyled ps-0">
                <li>
                    <a className="icon-link mb-1" href="#">
                    <svg className="bi" width="16" height="16"><use xlinkHref="#arrow-right-circle"/></svg>
                    Bootstrap quick start guide
                    </a>
                </li>
                <li>
                    <a className="icon-link mb-1" href="#">
                    <svg className="bi" width="16" height="16"><use xlinkHref="#arrow-right-circle"/></svg>
                    Bootstrap Webpack guide
                    </a>
                </li>
                <li>
                    <a className="icon-link mb-1" href="#">
                    <svg className="bi" width="16" height="16"><use xlinkHref="#arrow-right-circle"/></svg>
                    Bootstrap Parcel guide
                    </a>
                </li>
                <li>
                    <a className="icon-link mb-1" href="#">
                    <svg className="bi" width="16" height="16"><use xlinkHref="#arrow-right-circle"/></svg>
                    Bootstrap Vite guide
                    </a>
                </li>
                <li>
                    <a className="icon-link mb-1" href="#">
                    <svg className="bi" width="16" height="16"><use xlinkHref="#arrow-right-circle"/></svg>
                    Contributing to Bootstrap
                    </a>
                </li>
            </ul>
        </div>
    );
}

export default GuidesColumn;