/* eslint-disable jsx-a11y/anchor-is-valid */

function Header() {
    return (
        <header className="d-flex align-items-center pb-3 mb-5 border-bottom">
            <a href="/" className="d-flex align-items-center link-body-emphasis text-decoration-none">
                <svg className="bi me-2" width="40" height="32"><use xlinkHref="#weevilcorp"/></svg>
                <span className="fs-4">Beanweevil dev</span>
            </a>
        </header>
    );
}

export default Header;