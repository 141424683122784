
//https://stackoverflow.com/questions/59820954/syntaxerror-unknown-namespace-tags-are-not-supported-by-default
//https://getbootstrap.com/docs/5.3/examples/starter-template/
//https://blog.logrocket.com/using-react-popper-styled-components/

import './App.scss';

import ColorPicker from './ColorPicker';
import SVG from './SVG';
import Header from './Header';
import Main from './Main';
import Footer from './Footer';

function App() {
  return (
    <>
      <ColorPicker />    
      <SVG />
      <div className="col-lg-8 mx-auto p-4 py-md-5">
        <Header />
        <Main />
        <Footer />
      </div>
    </>
  );
}

export default App;
