/* eslint-disable jsx-a11y/anchor-is-valid */

function StarterProjectsColumn() {
    return (
        <div className="col-md-6">
            <h2 className="text-body-emphasis">Starter projects</h2>
            <p>Ready to go beyond the starter template? Check out these open source projects that you can quickly duplicate to a new GitHub repository.</p>
            <ul className="list-unstyled ps-0">
                <li>
                    <a className="icon-link mb-1" href="#" rel="noreferrer" target="_blank">
                    <svg className="bi" width="16" height="16"><use xlinkHref="#arrow-right-circle"/></svg>
                    Bootstrap npm starter
                    </a>
                </li>
                <li>
                    <a className="icon-link mb-1" href="#" rel="noreferrer" target="_blank">
                    <svg className="bi" width="16" height="16"><use xlinkHref="#arrow-right-circle"/></svg>
                    Bootstrap Parcel starter
                    </a>
                </li>
                <li>
                    <a className="icon-link mb-1" href="#" rel="noreferrer" target="_blank">
                    <svg className="bi" width="16" height="16"><use xlinkHref="#arrow-right-circle"/></svg>
                    Bootstrap Vite starter
                    </a>
                </li>
                <li>
                    <a className="icon-link mb-1" href="#" rel="noreferrer" target="_blank">
                    <svg className="bi" width="16" height="16"><use xlinkHref="#arrow-right-circle"/></svg>
                    Bootstrap Webpack starter
                    </a>
                </li>
            </ul>
        </div>
    );
}

export default StarterProjectsColumn;